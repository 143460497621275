import { Box, Button, Typography } from "@mui/material";
import EventCard from "Components/Admin/Events/EventCard";
import { eventsDataList } from "Pages/data";
import React from "react";
import { useNavigate } from "react-router-dom";

const EventsList = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "20px 10px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" color="secondary">
          List des événements
        </Typography>
        <Button
          sx={{
            width: "100px",
            fontSize: "16px",
            fontWeight: "400",
            backgroundColor: "alt.main",
            textTransform: "none",
            ":hover": {
              backgroundColor: "alt[400]",
            },
          }}
          onClick={() => navigate("/events/add")}
        >
          Ajouter
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {eventsDataList.map((event) => (
          <EventCard key={event._id} event={event} />
        ))}
      </Box>
    </>
  );
};

export default EventsList;
