import {
  BookmarkOutlined,
  BookmarksOutlined,
  BookOutlined,
  Diversity3Outlined,
  EventOutlined,
  FactoryOutlined,
  GamepadOutlined,
  Groups2Outlined,
  HomeOutlined,
  PersonOutline,
  PlaceOutlined,
  ShoppingBagOutlined,
  SocialDistanceOutlined,
} from "@mui/icons-material";
import { Box, List, ListItem, Typography } from "@mui/material";
import logo from "Images/logo.png";
import React from "react";
import { useLocation, useNavigate } from "react-router";

function Sidebar({ openSidebarToggle }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isNavItemActive = (item) => {
    if (location.pathname === "/" && item === "/") return true;
    return location.pathname.includes(item) && item !== "/";
  };

  const navItems = [
    {
      title: "Dashboard",
      icon: <HomeOutlined sx={{ fontSize: "25px" }} />,
      link: "/",
    },
    {
      title: "Jeux",
      icon: <GamepadOutlined sx={{ fontSize: "25px" }} />,
      link: "/games",
    },
    {
      title: "Mooc",
      icon: <BookOutlined sx={{ fontSize: "25px" }} />,
      link: "/moocs",
    },
    {
      title: "Commandes",
      icon: <ShoppingBagOutlined sx={{ fontSize: "25px" }} />,
      link: "/orders",
    },
    {
      title: "Entreprises",
      icon: <Diversity3Outlined sx={{ fontSize: "25px" }} />,
      link: "/companies",
    },
    {
      title: "Lieux",
      icon: <PlaceOutlined sx={{ fontSize: "25px" }} />,
      link: "/places",
    },
    {
      title: "Evénements",
      icon: <EventOutlined sx={{ fontSize: "25px" }} />,
      link: "/events",
    },
    {
      title: "Réservations",
      icon: <BookmarksOutlined sx={{ fontSize: "25px" }} />,
      link: "/reservations",
    },
    {
      title: "Utilisateurs",
      icon: <Groups2Outlined sx={{ fontSize: "25px" }} />,
      link: "/users",
    },
    {
      title: "Profile",
      icon: <PersonOutline sx={{ fontSize: "25px" }} />,
      link: "/profile",
    },
  ];
  return (
    <Box
      sx={{
        position:
          window.innerWidth < 1024 && openSidebarToggle ? "fixed" : "relative",
        zIndex: "1",
        width: openSidebarToggle ? "250px" : "60px",
        transition: "all 0.3s",
        height: "calc(100vh - 60px)",
        backgroundColor: "secondary.main",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px 0",
          gap: "20px",
          cursor: "pointer",
        }}
        onClick={()=>navigate('/')}
      >
        <Box
          sx={{
            backgroundImage: `url(${logo})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: openSidebarToggle ? "60px" : "30px",
            width: openSidebarToggle ? "60px" : "30px",
            borderRadius: "50%",
          }}
        />
        <Typography
          sx={{
            color: "white",
            fontSize: "1.5rem",
            fontWeight: "bold",
            display: !openSidebarToggle && "none",
          }}
        >
          Prêt à jouer
        </Typography>
      </Box>
      <List
        sx={{
          padding: "0",
          listStyleType: "none",
        }}
      >
        {navItems.map((item, index) => (
          <ListItem
            sx={{
              cursor: "pointer",
              padding: "20px 10px",
              backgroundColor: isNavItemActive(item.link) && "secondary.dark",
              "&:hover": {
                backgroundColor: "secondary.light",
              },
              display: "flex",
              justifyContent: openSidebarToggle ? "start" : "center",
              alignItems: "center",
              gap: "10px",
            }}
            key={index}
            onClick={() => navigate(item.link)}
          >
            {item.icon}
            <Typography
              sx={{
                marginLeft: "10px",
                fontSize: "1rem",
                fontWeight: "bold",
                display: !openSidebarToggle && "none",
                color: "#fff",
              }}
            >
              {item.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default Sidebar;
