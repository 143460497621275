import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Builder from "Components/Builder";
import CustomText from "Components/CustomText";
import ErrorText from "Components/ErrorText";
import React from "react";
import { useNavigate } from "react-router-dom";

const OrderGamesList = ({ games }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "6px",
        pt: "20px",
        flex: "1",
        overflowY: "auto",
      }}
    >
      <Typography
        fontSize="20px"
        fontWeight="bold"
        color={"grey"}
        sx={{
          pl: "15px",
        }}
      >
        Jeux
      </Typography>
      <Builder
        builder={() => {
          // if (isLoading) {
          //   return <LoadingShimmer />;
          // }
          if (games.length > 0) {
            return (
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    color={"grey"}
                    fontSize={"16px"}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    m="10px"
                    p="0 30px"
                  >
                    <Typography variant="h6">Nom</Typography>
                    <Typography variant="h6">Prix total</Typography>
                  </Box>
                  <Divider
                    sx={{
                      textAlign: "center",
                      height: "2px",
                      width: "100%",
                      backgroundColor: "black",
                    }}
                  />
                </Box>
                <Box>
                  <List>
                    {games.map((item) => (
                      <Box key={item._id}>
                        <ListItem
                          sx={{
                            padding: "10px 30px",
                            ":hover": {
                              boxShadow: 3,
                            },
                            cursor: "pointer",
                          }}
                          disablePadding
                          secondaryAction={
                            <Typography
                              variant="h6"
                              sx={{
                                color: "secondary.main",
                                textAlign: "start",
                                ml: "-50px",
                              }}
                            >
                              {item.total_price_after_reduction
                                ? item.total_price_after_reduction
                                : item.total_price}
                            </Typography>
                          }
                          onClick={() => navigate(`/games/${item.game._id}`)}
                        >
                          <ListItemText
                            id="worker-label-id"
                            sx={{ color: "black" }}
                            primaryTypographyProps={{
                              style: {
                                color: "secondary.main",
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                            }}
                            secondaryTypographyProps={{
                              style: {
                                color: "grey",
                                fontSize: "14px",
                              },
                            }}
                            primary={item.game.name}
                            secondary={
                              <CustomText
                                text={`x${item.quantity}`}
                                sx={{ fontSize: "12px", color: "grey" }}
                              />
                            }
                          />
                        </ListItem>
                        <Divider
                          sx={{
                            textAlign: "center",
                            height: "1px",
                            backgroundColor: "black",
                            m: "0 10px",
                          }}
                        />
                      </Box>
                    ))}
                  </List>
                </Box>
              </Box>
            );
          } else {
            return (
              <ErrorText text="Aucune jeux trouvé !" sx={{ height: "80%" }} />
            );
          }
        }}
      />
    </Box>
  );
};

export default OrderGamesList;
