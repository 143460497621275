import { Box, Typography } from "@mui/material";
import React from "react";

const ErrorText = ({ text, sx }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "secondary.main",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default ErrorText;
