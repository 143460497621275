import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ReservationItem from "Components/Reservations/ReservationItem";
import CustomButton from "Components/CustomButton";
import { reservationsDataList, moocsDataList } from "Pages/data";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const ReservationsList = () => {
  const navigate = useNavigate();
  const { moocId } = useParams();
  const mooc = moocsDataList.find((mooc) => mooc._id == moocId);
  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "10px",
        }}
      > */}
      <Typography variant="h3" fontWeight="bold" color="secondary" p={"20px"}>
        List des réservations
      </Typography>
      {/* <CustomButton
          text="Ajouter"
          sx={{
            width: "100px",
            backgroundColor: "alt.main",
            ":hover": {
              backgroundColor: "alt[400]",
            },
          }}
          onClick={() => navigate(`/reservations/add`)}
        />
      </Box> */}
      <Box
        sx={{
          ".css-r340ef-MuiTableCell-root": {
            color: "grey",
          },
          ".css-l5s4qo-MuiTableCell-root": {
            color: "grey",
          },
        }}
      >
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "primary.main",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Adress</TableCell>
                <TableCell>Date de debut</TableCell>
                <TableCell>Date de fin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservationsDataList.map((reservation) => (
                <ReservationItem
                  key={reservation._id}
                  reservation={reservation}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ReservationsList;
