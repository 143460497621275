import { Typography } from "@mui/material";
import React from "react";

const CustomText = ({ text, sx }) => {
  return (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: "400",
        color: "black",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        padding: "0 10px",
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};

export default CustomText;
