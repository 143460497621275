import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import { Box, } from "@mui/material";
import Builder from "Components/Builder";
import CustomButton from "Components/CustomButton";
import CustomText from "Components/CustomText";
import ErrorText from "Components/ErrorText";
import { moocsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const LessonDetails = () => {
  const navigate = useNavigate();
  const { moocId, lessonId } = useParams();
  const [lesson, setLesson] = useState(null);
  useEffect(() => {
    const mooc = moocsDataList.find((mooc) => mooc._id == moocId);
    if (mooc) {
      const data = mooc.lessons.find((item) => item._id == lessonId);
      setLesson(data);
    }
  }, [lessonId, moocId]);
  return (
    <Builder
      builder={() => {
        if (lesson) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <CustomButton
                text="Retour"
                sx={{
                  m: "10px",
                  color: "black",
                }}
                startIcon={<ArrowBackOutlined style={{ color: "black" }} />}
                onClick={() => navigate(`/moocs/${moocId}/lessons`)}
              />
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: 2,
                  padding: "20px",
                }}
              >
                <CustomText
                  sx={{
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                  text={`${lesson._id}. ${lesson.title}`}
                />
                <CustomText
                  sx={{
                    fontSize: "14px",
                    color: "grey",
                    padding: "10px",
                  }}
                  text={lesson.description}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <CustomButton
                  text="Précédent"
                  sx={{
                    bgcolor: "primary.main",
                    borderColor: lesson.previous_lesson ? "#000" : "grey",
                    margin: "10px",
                    color: "#000",
                    ":disabled": {
                      color: "grey",
                    },
                  }}
                  startIcon={
                    <ArrowBackOutlined
                      style={{
                        color: lesson.previous_lesson ? "#000" : "grey",
                      }}
                    />
                  }
                  disabled={!lesson.previous_lesson}
                  onClick={() =>
                    navigate(
                      `/moocs/${moocId}/lessons/${lesson.previous_lesson}`
                    )
                  }
                />
                <CustomButton
                  text="Suivant"
                  sx={{
                    bgcolor: "primary.main",
                    borderColor: lesson.next_lesson ? "#000" : "grey",
                    margin: "10px",
                    color: "#000",
                    ":disabled": {
                      color: "grey",
                    },
                  }}
                  endIcon={
                    <ArrowForwardOutlined
                      style={{ color: lesson.next_lesson ? "#000" : "grey" }}
                    />
                  }
                  disabled={!lesson.next_lesson}
                  onClick={() =>
                    navigate(`/moocs/${moocId}/lessons/${lesson.next_lesson}`)
                  }
                />
              </Box>
            </Box>
          );
        } else {
          return <ErrorText text={"leçon n'existe pas"} />;
        }
      }}
    />
  );
};

export default LessonDetails;
