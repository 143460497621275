import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PlaceItem from "Components/Places/PlaceItem";
import CustomButton from "Components/CustomButton";
import { placesDataList } from "Pages/data";
import React from "react";
import { useNavigate } from "react-router-dom";

const PlacesList = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "10px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" color="secondary" p={"10px"}>
          List des Lieux
        </Typography>
        <CustomButton
          text="Ajouter"
          sx={{
            width: "100px",
            backgroundColor: "alt.main",
            ":hover": {
              backgroundColor: "alt[400]",
            },
          }}
          onClick={() => navigate(`/places/add`)}
        />
      </Box>
      <Box
        sx={{
          ".css-r340ef-MuiTableCell-root": {
            color: "grey",
          },
          ".css-l5s4qo-MuiTableCell-root": {
            color: "grey",
          },
        }}
      >
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "primary.main",
            color: "grey",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Adress</TableCell>
                <TableCell>Capacité</TableCell>
                <TableCell>Prix</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "grey",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {placesDataList.map((place) => (
                <PlaceItem key={place._id} place={place} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default PlacesList;
