import { Box, Typography } from "@mui/material";
import Builder from "Components/Builder";
import CustomButton from "Components/CustomButton";
import ErrorText from "Components/ErrorText";
import LoadingShimmer from "Components/LoadingShimmer";
import TextItem from "Components/TextItem";
import { eventsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const EventPlaceDetails = ({ eventId }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [place, setPlace] = useState(null);
  useEffect(() => {
    const data = eventsDataList.find((item) => item._id == eventId);
    if (data) {
      setPlace(data.place_reservation.place);
    }
  }, [eventId]);
  return (
    <Box
      sx={{
        mt: "20px",
        p: "10px",
        border: "1px solid black",
      }}
    >
      <Builder
        builder={() => {
          if (isLoading) {
            return <LoadingShimmer />;
          }
          if (place) {
            return (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    À propos de lieu
                  </Typography>
                  <CustomButton
                    text={"Voir plus"}
                    sx={{
                      bgcolor: "blue",
                      p: "2px 10px",
                    }}
                    onClick={() => navigate(`/places/${place._id}`)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "70px",
                    rowGap: "20px",
                    padding: "10px",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <TextItem title="Nom de lieu" subTitle={place.name} />
                  <TextItem title="Adresse" subTitle={place.address} />
                </Box>
              </Box>
            );
          } else {
            return <ErrorText text={"Aucun lieu trouvé !"} />;
          }
        }}
      />
    </Box>
  );
};

export default EventPlaceDetails;
