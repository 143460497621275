import {
  Box,
  Typography,
} from "@mui/material";
import Builder from "Components/Builder";
import CustomGamesList from "Components/CustomGamesList";
import ErrorText from "Components/ErrorText";
import LoadingShimmer from "Components/LoadingShimmer";
import { placesDataList } from "Pages/data";
import React, { useEffect, useState } from "react";

const PlaceGamesList = ({ placeId }) => {
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const data = placesDataList.find((item) => item._id == placeId);
    if (data) {
      setGames(data.games);
    }
  }, [placeId]);
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "6px",
        pt: "20px",
        flex: "1",
        overflowY: "auto",
      }}
    >
      <Typography
        fontSize="20px"
        fontWeight="bold"
        color={"grey"}
        sx={{
          pl: "15px",
        }}
      >
        Jeux
      </Typography>
      <Builder
        builder={() => {
          if (isLoading) {
            return <LoadingShimmer />;
          }
          if (games.length > 0) {
            return <CustomGamesList games={games} />;
          } else {
            return (
              <ErrorText text="Aucune jeux trouvé !" sx={{ height: "80%" }} />
            );
          }
        }}
      />
    </Box>
  );
};

export default PlaceGamesList;
