import {
  ArrowBackOutlined,
  BorderColorOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Builder from "Components/Builder";
import ErrorText from "Components/ErrorText";
import { companiesDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManagerDetails from "Components/Admin/Companies/ManagerDetails";
import TextItem from "Components/TextItem";
import CollaboratorList from "Components/Admin/Companies/CollaboratorList";

const CompanyDetails = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [company, setCompany] = useState(null);
  useEffect(() => {
    const data = companiesDataList.find((item) => item._id == companyId);
    setCompany(data);
  }, [companyId]);
  return (
    <Builder
      builder={() => {
        if (company) {
          return (
            <Box>
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    startIcon={
                      <ArrowBackOutlined
                        sx={{
                          color: "grey",
                          height: "15px",
                          width: "18px",
                        }}
                      />
                    }
                    sx={{
                      color: "grey",
                      textTransform: "none",
                      fontSize: "14px",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Retour
                  </Button>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={"secondary.light"}
                  >
                    {company.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {
                      navigate(`edit`);
                    }}
                  >
                    <BorderColorOutlined sx={{ color: "alt.main" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {}}
                  >
                    <DeleteOutline sx={{ color: "alt.main" }} />
                  </IconButton>
                </Box>
              </Box>
              {/* Body */}
              <Box
                sx={{
                  p: "20px",
                }}
              >
                {/* Details */}
                <Box
                  sx={{
                    p: "10px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    À propos
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "70px",
                      rowGap: "20px",
                      padding: "10px",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <TextItem
                      title="Nom de l'entreprise"
                      subTitle={company.name}
                    />
                    <TextItem title="Adresse" subTitle={company.address} />
                    <TextItem title="Email" subTitle={company.email} />
                    <TextItem
                      title="Description"
                      subTitle={company.description}
                    />
                    <TextItem title="Téléphone" subTitle={company.phone} />
                  </Box>
                </Box>
                {/* manager and collaborators */}
                <Box
                  sx={{
                    height: "45vh",
                    display: "flex",
                    mt: "20px",
                    gap: "10px",
                  }}
                >
                  {/* manager */}
                  <ManagerDetails companyId={company._id} />
                  {/* Collaborateur */}
                  <CollaboratorList companyId={company._id} />
                </Box>
              </Box>
            </Box>
          );
        } else {
          return <ErrorText text={"Societé n'existe pas"} />;
        }
      }}
    />
  );
};

export default CompanyDetails;
