import { ArrowBackOutlined } from "@mui/icons-material";
import { Box, Button, colors, Typography } from "@mui/material";
import CustomButton from "Components/CustomButton";
import LessonItem from "Components/Admin/Lessons/LessonItem";
import { moocsDataList } from "Pages/data";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const LessonsList = () => {
  const navigate = useNavigate();
  const { moocId } = useParams();
  const mooc = moocsDataList.find((mooc) => mooc._id == moocId);
  const lessons = mooc?.lessons;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "10px",
          // position: "sticky",
          // top: "0",
          // zIndex: "1",
        }}
      >
        <CustomButton
          text="Retour"
          sx={{
            color: "black",
          }}
          startIcon={<ArrowBackOutlined style={{ color: "black" }} />}
          onClick={() => navigate(`/moocs/${moocId}`)}
        />
        <CustomButton
          text="Ajouter"
          sx={{
            width: "100px",
            backgroundColor: "alt.main",
            ":hover": {
              backgroundColor: "alt[400]",
            },
          }}
          onClick={() => navigate(`/moocs/${moocId}/lessons/add`)}
        />
      </Box>
      <Typography variant="h3" fontWeight="bold" color="secondary" p={"10px"}>
        List des lessons
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {lessons.map((lesson) => (
          <LessonItem key={lesson._id} lesson={lesson} />
        ))}
      </Box>
    </>
  );
};

export default LessonsList;
