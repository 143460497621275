import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import Home from '../Pages/Home';
import { useDispatch, useSelector } from "react-redux";
import NotFoundError from "Components/NotFoundError";
import ClientHome from "Pages/Client/ClientHome";

// const TestHome = () => {
//   return (
//     <Box>
//       <MainHeader />
//       <MainLayout>
//         <Box
//           sx={{
//             fontSize: "38px",
//             fontWeight: "bold",
//             color: "black",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             height: "100%",
//           }}
//         >
//           Bienvenue chez CS Consulting
//         </Box>
//       </MainLayout>
//     </Box>
//   );
// };

const ClientRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const isLoading = useSelector((state) => selectGlobalIsLoading(state));
  const user = useSelector((state) => state.authentification?.user);

  return (
    <Routes>
      <Route exact path="/" element={<ClientHome />} />
      <Route path="*" element={<NotFoundError text={"404"} />} />
    </Routes>
  );
};

export default ClientRoute;
