import { useTheme } from "@emotion/react";
import { CloseOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const CustomAutoComplete = ({
  options,
  filterOptions,
  toggleOption,
  hint,
  initialValue,
  name,
  value,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ".MuiAutocomplete-input": {
          color: "green !important",
          fontSize: "14px",
        },
        // pl: "15px",
        // "& .css-63uuw-MuiAutocomplete-root .MuiOutlinedInput-root": {
        //   p: "2px",
        // },
      }}
    >
      <Typography
        sx={{
          color: "grey",
          fontSize: "14px",
          fontWeight: "400",
          mb: ".6rem",
        }}
      >
        {hint}
      </Typography>
      <Autocomplete
        sx={{
          flex: "1",
        }}
        name={name}
        multiple
        freeSolo
        defaultValue={initialValue}
        value={value}
        options={options}
        getOptionLabel={(option) => option}
        ChipProps={{
          style: {
            fontSize: "14px",
            color: "grey",
          },
          deleteIcon: (
            <CloseOutlined
              sx={{
                color: `${theme.palette.grey[600]} !important`,
                ":hover": {
                  color: theme.palette.grey[600],
                },
                width: "15px",
                height: "15px",
              }}
            />
          ),
        }}
        slotProps={{
          clearIndicator: {
            style: {
              color: "grey",
            },
          },
          popupIndicator: {
            style: {
              color: "grey",
            },
          },
        }}
        ListboxProps={{
          style: {
            backgroundColor: "white",
            color: "black",
          },
        }}
        // disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderColor: theme.palette.grey[200],
              borderRadius: "5px",
              color: "red",
            }}
            {...params}
          />
        )}
        renderOption={(props, option, { selected }) =>
          option && (
            <MenuItem
              {...props}
              key={option}
              value={option}
              sx={{
                bgcolor: selected && `${theme.palette.grey[200]} !important`,
                borderBottom: "1px solid",
                borderColor: theme.palette.grey.light,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  gap: "10px",
                  textWrap: "nowrap",
                }}
              >
                <Typography>{option.name}</Typography>
              </Box>
            </MenuItem>
          )
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Box
              key={option}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                bgcolor: theme.palette.grey[200],
                borderRadius: "5px",
                p: "5px",
                color: "black",
              }}
              {...getTagProps({ index })}
            >
              <Typography>{option.name}</Typography>
            </Box>
          ))
        }
        onChange={(event, value) => toggleOption(value)}
        noOptionsText="Aucun utilisateur trouvé!"
        filterOptions={filterOptions}
      />
    </Box>
  );
};

export default CustomAutoComplete;
