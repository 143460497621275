import {
    getMe,
    getUser,
    login as loginFromAPI,


} from "../../Services/Users.services";

import { jwtDecode } from "jwt-decode";

export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_OUT = "SIGN_OUT";
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const RESTORE_TOKEN = 'RESTORE_TOKEN'

export const setInitialState = (user) => ({
    type: SET_INITIAL_STATE,
    payload: user,
});


export const login = (values) => async (dispatch) => {
    dispatch({ type: SIGN_IN });

    try {
        const result = await loginFromAPI(values);
        if (result && ["ADMIN", "CLIENT", "COLLABORATOR", "COMPANY"].includes(result.role)) {
            localStorage.setItem("@user", result.token);
            const decoded = jwtDecode(result.token);
            const res = await getMe(decoded.userId);
            const user = res?.user
            dispatch({
                type: SIGN_IN_SUCCESS,
                payload: { user, token: result.token }
            });
            return result;
        } else {
            dispatch({ type: SIGN_IN_ERROR, payload: "Role non autorisé" });
            return false;
        }
    } catch (error) {
        dispatch({ type: SIGN_IN_ERROR, payload: "Erreur de connexion" });
        return false;
    }
};

export const fetchUser = (id) => (dispatch) => {
    return getUser(id)
        .then(

            (result) => {
                if (result) {

                    dispatch({
                        type: SIGN_IN_SUCCESS,
                        payload: result,
                    });

                }
                return result
            }

        );
};

export const signOut = () => async (dispatch) => {
    try {
        await localStorage.removeItem('@user');
        dispatch({ type: SIGN_OUT });
    } catch (e) {
        console.log(e);
    }
};