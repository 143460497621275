import { Box, Typography } from "@mui/material";
import React from "react";
import logo from "Images/logo.png";
import {
  FacebookOutlined,
  Instagram,
  LinkedIn,
  X,
  YouTube,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

function Footer() {
  const isMobilePhone = window.innerWidth < 600;
  const links = [
    {
      icon: <FacebookOutlined color="primary" />,
      link: "https://www.facebool.com",
    },
    {
      icon: <YouTube color="primary" />,
      link: "https://www.facebool.com",
    },
    {
      icon: <Instagram color="primary" />,
      link: "https://www.facebool.com",
    },
    {
      icon: <LinkedIn color="primary" />,
      link: "https://www.facebool.com",
    },
    {
      icon: <X color="primary" />,
      link: "https://www.facebool.com",
    },
  ];
  return (
    <Box
      sx={{
        height: "45vh",
        backgroundColor: "secondary.main",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              height: "60px",
              width: "60px",
              backgroundImage: `url(${logo})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              borderRadius: "50%",
            }}
          />
          <Typography
            sx={{
              color: "tertiary.main",
              fontSize: "32px",
              fontWeight: "bold",
              letterSpacing: "2px",
            }}
          >
            Prêt à jouer
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: isMobilePhone && "column",
            width: "100%",
          }}
        >
          <Typography
            style={{
              flex: "1",
              textAlign: "center",
              fontSize: "16px",
              padding: "0 20px",
            }}
          >
            Jeux de société, jeux vidéo, événements ludiques, MOOC et plus
            encore. Explorez, apprenez et amusez-vous avec nous !
          </Typography>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
            }}
          >
            {links.map((item, index) => {
              return (
                <Link key={index} to={item.link}>
                  {item.icon}
                </Link>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            height: "1px",
            width: "100%",
            backgroundColor: "tertiary.main",
            padding: "0 20px",
          }}
        />
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",
            width: "100%",
          }}
        >
          Prêtàjouer @ 2024. Tous droits réservés.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
