import refreshToken from "../helpers/functions";

const API = process.env.REACT_APP_API_URL;

//////////////////////////////////
// GAME SERVICES
//////////////////////////////////

// Get all games
export const getAllGames = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}games`);
    return response.data;
  } catch (error) {
    console.error("Error getting all games:", error);
    throw error;
  }
};

// Get game details by gameId
export const getGameDetails = async (gameId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}games/${gameId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting game details:", error);
    throw error;
  }
};

// Create a new game
export const createGame = async (gameData, imageFile) => {
  let http = await refreshToken();
  const formData = new FormData();
  formData.append("image", imageFile);
  for (let key in gameData) {
    formData.append(key, gameData[key]);
  }
  
  try {
    const response = await http.post(`${API}games`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    console.error("Error creating game:", error);
    throw error;
  }
};

// Update game details
export const updateGameDetails = async (gameId, gameData, imageFile) => {
  let http = await refreshToken();
  const formData = new FormData();
  if (imageFile) formData.append("image", imageFile);
  for (let key in gameData) {
    formData.append(key, gameData[key]);
  }

  try {
    const response = await http.put(`${API}games/${gameId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response.data;
  } catch (error) {
    console.error("Error updating game details:", error);
    throw error;
  }
};

// Add game reduction
export const addReduction = async (gameId, reductionData) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}games/${gameId}/reduction`, reductionData);
    return response.data;
  } catch (error) {
    console.error("Error adding game reduction:", error);
    throw error;
  }
};

// Delete game reduction
export const deleteReduction = async (gameId) => {
  let http = await refreshToken();
  try {
    const response = await http.delete(`${API}games/${gameId}/reduction`);
    return response.data;
  } catch (error) {
    console.error("Error deleting game reduction:", error);
    throw error;
  }
};

// Delete a game by gameId
export const deleteGame = async (gameId) => {
  let http = await refreshToken();
  try {
    const response = await http.delete(`${API}games/${gameId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting game:", error);
    throw error;
  }
};

//////////////////////////////////
// MOOC SERVICES
//////////////////////////////////

// Get MOOC details by gameId
export const getMoocByGameId = async (gameId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}games/${gameId}/mooc`);
    return response.data;
  } catch (error) {
    console.error("Error getting MOOC for game:", error);
    throw error;
  }
};

// Create a new MOOC for a game
export const createMooc = async (gameId, moocData) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}games/${gameId}/mooc`, moocData);
    return response.data;
  } catch (error) {
    console.error("Error creating MOOC:", error);
    throw error;
  }
};
