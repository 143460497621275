import { Button, ListItem, ListItemText, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const UserItem = ({ user,onClick }) => {
  const navigate = useNavigate();
  return (
    <ListItem
    sx={{
      padding: "10px 30px",
      ":hover": {
        boxShadow: 3,
      },
      cursor: "pointer",
    }}
    disablePadding
    secondaryAction={
      <Typography
        variant="h6"
        sx={{
          color: "secondary.main",
          textAlign: "start",
          ml: "-50px",
        }}
      >
        {user.phone}
      </Typography>
    }
    onClick={onClick}
  >
    <ListItemText
      id="worker-label-id"
      sx={{ color: "black" }}
      primaryTypographyProps={{
        style: {
          color: "secondary.main",
          fontSize: "14px",
          fontWeight: "500",
        },
      }}
      secondaryTypographyProps={{
        style: {
          color: "grey",
          fontSize: "14px",
        },
      }}
      primary={`${user.first_name} ${user.last_name}`}
      secondary={user.email}
    />
  </ListItem>
  );
};

export default UserItem;
