import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Typography,
  Box,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import logo from "Images/logo.png";
import { ShoppingCart } from "@mui/icons-material";

const Navbar = () => {
  const location = useLocation();

  return (
    <AppBar position="fixed" color="inherit">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <img src={logo} alt="My Store" height="35px" />
          <Typography component={Link} to="/" variant="h6" color="inherit">
            Pret à jouer
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography component={Link} to="/about" variant="h6" color="inherit">
            About
          </Typography>
          <Typography
            component={Link}
            to="/products"
            variant="h6"
            color="inherit"
          >
            Products
          </Typography>
          {location.pathname !== "/cart" && (
            <div>
              <IconButton
                component={Link}
                to="/cart"
                aria-label="Show cart items"
                color="inherit"
              >
                <Badge color="secondary">
                  <ShoppingCart />
                </Badge>
              </IconButton>
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
