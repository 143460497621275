import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  BorderColorOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CustomText from "Components/CustomText";
import TextItem from "Components/TextItem";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UserDetails = ({ user, setOpen, setSelectedUser }) => {
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      setSelectedUser(null);
    };
  }, []);
  return (
    <Box
      sx={{
        bgcolor: "white",
        width: "400px",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "20px",
        }}
      >
        <Button
          startIcon={
            <ArrowBackOutlined
              sx={{
                color: "grey",
                height: "15px",
                width: "18px",
              }}
            />
          }
          sx={{
            color: "grey",
            textTransform: "none",
            fontSize: "14px",
          }}
          onClick={() => setOpen(false)}
        >
          Informations général
        </Button>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <IconButton
            sx={{
              border: "1px solid",
              borderRadius: "4px",
              borderColor: "grey",
            }}
            onClick={() => {
              setOpen(false);
              navigate(`/users/${user._id}/edit`);
            }}
          >
            <BorderColorOutlined sx={{ color: "alt.main" }} />
          </IconButton>
          <IconButton
            sx={{
              border: "1px solid",
              borderRadius: "4px",
              borderColor: "grey",
            }}
            onClick={() => {}}
          >
            <DeleteOutline sx={{ color: "alt.main" }} />
          </IconButton>
        </Box>
      </Box>
      {/* Body */}
      <Box
        sx={{
          p: "20px",
        }}
      >
        {/* Details */}
        <Box
          sx={{
            p: "10px",
            border: "1px solid black",
          }}
        >
          <Box>
            <TextItem
              title="Nom"
              subTitle={user.first_name + " " + user.last_name}
            />
            <TextItem title="Adresse" subTitle={user.address} />
            <TextItem title="Email" subTitle={user.email} />
            <TextItem title="Téléphone" subTitle={user.phone} />
            <TextItem title="Role" subTitle={user.role} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserDetails;
