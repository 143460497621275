import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section id="hero">
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Typography variant="h1" style={{ fontWeight: "800" }}>
            SALE
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">UP TO 40% OFF</Typography>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to="/products"
            size="large"
            type="button"
            variant="outlined"
            // color="primary"
          >
            <Typography variant="h5">SHOP NOW</Typography>
          </Button>
        </Grid>
      </Grid>
    </section>
  );
};

export default Hero;
