import { Button, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const PlaceItem = ({ place }) => {
  const navigate = useNavigate();
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        ":hover": {
          boxShadow: 3,
        },
        cursor: "pointer",
      }}
      onClick={() => navigate(`/places/${place._id}`)}
    >
      <TableCell>{place.name}</TableCell>
      <TableCell>{place.address}</TableCell>
      <TableCell>{place.capacity}</TableCell>
      <TableCell>{place.paying ? place.price : 'Gratuit'}</TableCell>
      <TableCell
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
        align="center"
      >
        <Button
          variant="outlined"
          sx={{
            color: "grey",
            borderColor: "grey",
            border: "1px solid",
            textTransform: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/places/${place._id}/edit`);
          }}
        >
          Modifier
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "1px solid",
            color: "grey",
            borderColor: "grey",
            textTransform: "none",
          }}
          onClick={() => {}}
        >
          Supprimer
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default PlaceItem;
