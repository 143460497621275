import { Box, Typography } from "@mui/material";
import React from "react";

const NotFoundError = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: "secondary.main",
          fontWeight: "bold",
          fontFamily: "monospace",
          fontSize: "5rem",
        }}
      >
        404
      </Typography>
    </Box>
  );
};

export default NotFoundError;
