import { Skeleton } from "@mui/material";
import React from "react";

const LoadingShimmer = () => {
  return (
    <Skeleton
      animation="pulse"
      variant="rectangular"
      sx={{
        bgcolor: "grey.300",
        m: "20px",
        borderRadius: "6px",
        height: "80px",
      }}
    />
  );
};

export default LoadingShimmer;
