import { Box, Button } from "@mui/material";
import React from "react";
import logo from "Images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import CustomText from "Components/CustomText";

const LessonItem = ({ lesson }) => {
  const navigate = useNavigate();
  const { moocId } = useParams();
  return (
    <Box
      sx={{
        boxShadow: 1,
        borderRadius: 1,
        height: "200px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px",
        maxWidth: "45%",
        padding: "10px",
        ":hover": {
          boxShadow: 3,
          cursor: "pointer",
        },
      }}
      onClick={() => navigate(`/moocs/${moocId}/lessons/${lesson._id}`)}
    >
      <img
        src={logo}
        alt={lesson.title}
        style={{
          width: "100px",
          height: "100px",
          objectFit: "cover",
          boxShadow: 1,
          borderRadius: "14px",
          border: "1px solid #f0f0f0",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
          gap: 1,
          marginTop: 1,
        }}
      >
        <CustomText
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            WebkitLineClamp: "2",
          }}
          text={lesson.title}
        />
        <CustomText
          sx={{
            fontSize: "14px",
            color: "grey",
            WebkitLineClamp: "2",
          }}
          text={lesson.description}
        />
      </Box>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Button
          sx={{
            width: "100px",
            bgcolor: "error.main",
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "none",
            padding: "5px 15px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            // deleteLesson(lesson._id);
          }}
        >
          Supprimer
        </Button>
        <Button
          sx={{
            width: "100px",
            bgcolor: "alt.main",
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "none",
            padding: "5px 15px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/moocs/${moocId}/lessons/${lesson._id}/edit`);
          }}
        >
          Modifier
        </Button>
      </Box>
    </Box>
  );
};

export default LessonItem;
