import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import OrderDetails from "./OrderDetails";
import NotFoundError from "Components/NotFoundError";
import OrdersList from "./OrdersList";

const Orders = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Routes>
        <Route path="/" element={<OrdersList />} />
        <Route path="/:orderId" element={<OrderDetails />} />
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Box>
  );
};

export default Orders;
