import refreshToken from "../helpers/functions";

const API = process.env.REACT_APP_API_URL;

///////////////////////////
////// USERS ROUTES //////
///////////////////////////

// Get All users details
export const getAllUser = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get details of the connected user
export const getMe = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Update details of the connected user
export const updateMe = async (userData) => {
  let http = await refreshToken();
  try {
    const response = await http.put(`${API}users/me`, userData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Change password of the connected user
export const updatePassword = async (passwordData) => {
  let http = await refreshToken();
  try {
    const response = await http.put(
      `${API}users/me/update-password`,
      passwordData
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Delete connected user account
export const deleteMe = async () => {
  let http = await refreshToken();
  try {
    const response = await http.delete(`${API}users/me`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get user details by ID
export const getUser = async (id) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Update user details by ID (admin)
export const updateUser = async (id, userData) => {
  let http = await refreshToken();
  try {
    const response = await http.put(`${API}users/${id}`, userData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Delete user by ID (admin)
export const deleteUser = async (id) => {
  let http = await refreshToken();
  try {
    const response = await http.delete(`${API}users/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// login
export const login = async (credentials) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}users/login`, credentials);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Admin register
export const registerAdmin = async (userData) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}users/admins/register`, userData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Client register
export const registerClient = async (userData) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}users/clients/register`, userData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Client register Company Admin
export const registerCompanyAdmin = async (userData) => {
  let http = await refreshToken();
  try {
    const response = await http.post(
      `${API}users/company-admins/register`,
      userData
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Generate OTP
export const generateOTP = async () => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}users/generate-otp`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Verify OTP
export const verifyOTP = async (otp) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}users/verify-otp`, { otp });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Reset password
export const resetPassword = async (passwordData) => {
  let http = await refreshToken();
  try {
    const response = await http.put(`${API}users/reset-password`, passwordData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

///////////////////////////
////// EVENTS ROUTES //////
///////////////////////////

// get all events of a client
export const clientsEvent = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/events`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// leave event by Id
export const leaveEvent = async (id) => {
  let http = await refreshToken();
  try {
    const response = await http.delete(`${API}users/me/events/${id}/leave`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

///////////////////////////
////// ORDERS ROUTES //////
///////////////////////////

// get all client orders
export const clientOrders = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/orders`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get client order
export const clientOrder = async (id) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/orders/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// create order
export const createOrder = async (data) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}users/me/orders`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

///////////////////////////
////// CART ROUTES ////////
///////////////////////////

// Get client cart details
export const getClientCart = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/cart`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Add item to cart
export const addCartItem = async (itemData) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}users/me/cart`, itemData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Update cart item quantity
export const updateCartItemQuantity = async (itemId, quantity) => {
  let http = await refreshToken();
  try {
    const response = await http.put(`${API}users/me/cart/${itemId}`, {
      quantity,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Remove cart item
export const deleteCartItem = async (itemId) => {
  let http = await refreshToken();
  try {
    const response = await http.delete(`${API}users/me/cart/${itemId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

///////////////////////////
////// MOOC ROUTES ////////
///////////////////////////

// Get all Moocs of a client
export const getClientMoocs = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/moocs`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get specific Mooc for a client by Mooc ID
export const getClientMooc = async (moocId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/moocs/${moocId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Submit lesson for a Mooc
export const submitLesson = async (moocId, lessonId, data) => {
  let http = await refreshToken();
  try {
    const response = await http.post(
      `${API}users/me/moocs/${moocId}/lessons/${lessonId}/submit`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Unenroll from a Mooc
export const unenrollMooc = async (moocId) => {
  let http = await refreshToken();
  try {
    const response = await http.delete(
      `${API}users/me/moocs/${moocId}/unenroll`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Submit final test for a Mooc
export const submitFinalTest = async (moocId, data) => {
  let http = await refreshToken();
  try {
    const response = await http.post(
      `${API}users/me/moocs/${moocId}/final-test/submit`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

////////////////////////////
///// CERTIFICATE ROUTES ////
////////////////////////////

// Get all certificates for the client
export const getClientCertificates = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/certificates`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get specific certificate by certificate ID
export const getClientCertificate = async (certificateId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(
      `${API}users/me/certificates/${certificateId}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

///////////////////////////
///// TICKET ROUTES ///////
///////////////////////////

// Get all tickets for the client
export const getClientTickets = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/tickets`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get specific ticket by ticket ID
export const getClientTicket = async (ticketId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/tickets/${ticketId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//////////////////////////////
///// NOTIFICATION ROUTES /////
//////////////////////////////

// Get all notifications for the client
export const getClientNotifications = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}users/me/notifications`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Get specific notification by notification ID
export const getClientNotification = async (notificationId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(
      `${API}users/me/notifications/${notificationId}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
