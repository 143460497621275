import React from "react";
import { Typography, Container, Grid } from "@mui/material";

const Footer = () => {
  return (
    <section id="footer">
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          justify="center"
          //
        >
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: "500" }}>
              &copy; 2021 Danniel's Store
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Footer;
