import { Box, Typography } from "@mui/material";
import React from "react";
import image from "Images/contact.jpg";
import { Link } from "react-router-dom";
import {
  EmailOutlined,
  PhoneOutlined,
  PlaceOutlined,
} from "@mui/icons-material";
import bg from "Images/t5.jpg";

const ContactItem = ({ contact }) => {
  return (
    <Link to={contact.link} target="_blank" style={{ textDecoration: "none" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {contact.icon}
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "secondary.main",
          }}
        >
          {contact.title}
        </Typography>
      </Box>
    </Link>
  );
};

const Contact = () => {
  const isMobilePhone = window.innerWidth < 600;
  const contacts = [
    {
      title: "contact@pretajouer.fr",
      icon: (
        <EmailOutlined
          sx={{
            bgcolor: "secondary.main",
            fontSize: "50px",
            borderRadius: "50%",
            padding: "10px",
          }}
        />
      ),
    },
    {
      title: "+33751246189",
      icon: (
        <PhoneOutlined
          sx={{
            bgcolor: "secondary.main",
            fontSize: "50px",
            borderRadius: "50%",
            padding: "10px",
          }}
        />
      ),
    },
    {
      title:
        "Centre Tertiaire la Grande Pyramide 1 Rue de l'Equerre 13800 Istres",
      icon: (
        <PlaceOutlined
          sx={{
            bgcolor: "secondary.main",
            fontSize: "50px",
            borderRadius: "50%",
            padding: "10px",
          }}
        />
      ),
    },
  ];
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "start",
        flexDirection: "column",

        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      id="contact"
    >
      <Typography
        sx={{
          color: "secondary.main",
          fontWeight: "bold",
          fontSize: "2rem",
          textAlign: "left",
          margin: "60px 10px 10px 10px",
          alignSelf: "start",
        }}
      >
        Contact
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "secondary.main",
              textAlign: "center",
            }}
          >
            Soumettre un jeu Vous êtes un(e)
            <br /> créateur(trice) qui aimerait nous soumettre un jeu? N'hésitez
            pas à nous contacter
          </Typography>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            {contacts.map((item) => (
              <Box
                key={item.title}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {item.icon}
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "secondary.main",
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {/* <PopUp open={addSuccessOpen} setOpen={setAddSuccessOpen}>
        <AddSuccessPopUp
          onClick={() => setAddSuccessOpen(false)}
          title={"Email envoyé avec succès"}
        />
      </PopUp> */}
      {/* <LoadingOverlay open={addAlertLoading} /> */}
    </Box>
  );
};

export default Contact;
