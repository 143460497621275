import { Box, Typography } from "@mui/material";
import React from "react";
import image from "Images/about.png";
import bg from "Images/t3.jpg";

const About = () => {
  const isMobilePhone = window.innerWidth < 600;
  return (
    <Box
      sx={{
        height: isMobilePhone ? "100%" : "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",

        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      id="about"
    >
      <Typography
        sx={{
          display: { xs: "none", sm: "block" },
          color: "secondary.main",
          fontWeight: "bold",
          fontSize: "2rem",
          textAlign: "left",
          margin: "60px 10px 10px 10px",
          alignSelf: "start",
        }}
      >
        À propos
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70%",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "32px",
              color: "secondary.main",
              fontWeight: "bold",
            }}
          >
            À propos de nous
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              color: "secondary.main",
              textAlign: "center",
              p: "10px",
              width: isMobilePhone ? "100%" : "50%",
            }}
          >
            Chez Prêt à jouer, nous sommes passionnés par l'univers du jeu sous
            toutes ses formes. Que vous soyez un amateur de jeux de société, un
            joueur vidéo aguerri, ou un passionné de stratégie, nous avons tout
            ce qu'il vous faut. Notre mission est de rendre le jeu accessible à
            tous et de créer des expériences ludiques inoubliables. Nous offrons
            une large gamme de jeux et d'accessoires pour tous les âges et tous
            les niveaux, ainsi que des services uniques comme l'organisation
            d'événements ludiques (privés et publics), des tournois et des
            formations en ligne (MOOC) pour apprendre à jouer et à maîtriser vos
            jeux préférés. Rejoignez-nous et plongez dans l'aventure du jeu avec
            Prêt à jouer !
          </Typography>
        </Box>
        {/* <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "15px",
            flex: "1",
          }}
        /> */}
      </Box>
    </Box>
  );
};

export default About;
