import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { eventsDataList } from "Pages/data";
import Builder from "Components/Builder";
import {
  ArrowBackOutlined,
  BorderColorOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import CustomText from "Components/CustomText";
import ErrorText from "Components/ErrorText";
import { formatDate } from "helpers/format_date";
import CustomButton from "Components/CustomButton";
import PopUp from "Components/Popup";
import UserDetails from "../Users/UserDetails";
import EventGamesList from "Components/Admin/Events/EventGamesList";
import EventParticipentsList from "Components/Admin/Events/EventParticipentsList";
import EventPlaceDetails from "Components/Admin/Events/EventPlaceDetails";
import TextItem from "Components/TextItem";

const EventDetails = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [event, setEvent] = useState(null);
  const getEventTypeLabel = (type) => {
    const eventTpeLable = {
      public: "Public",
      private: "Privé",
    };
    return eventTpeLable[type] || "Public";
  };
  const getEventPlaceTypeLabel = (placeType) => {
    const placeTypeLabel = {
      local: "Local",
      place: "Réservation de lieu",
    };
    return placeTypeLabel[placeType] || "Local";
  };
  useEffect(() => {
    const data = eventsDataList.find((item) => item._id == eventId);
    setEvent(data);
  }, []);
  return (
    <Builder
      builder={() => {
        if (event) {
          return (
            <Box>
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    startIcon={
                      <ArrowBackOutlined
                        sx={{
                          color: "grey",
                          height: "15px",
                          width: "18px",
                        }}
                      />
                    }
                    sx={{
                      color: "grey",
                      textTransform: "none",
                      fontSize: "14px",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Retour
                  </Button>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={"secondary.light"}
                  >
                    {event.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <CustomButton
                    text={"Commencer l'événement"}
                    sx={{ backgroundColor: "blue" }}
                  />
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {
                      navigate(`edit`);
                    }}
                  >
                    <BorderColorOutlined sx={{ color: "alt.main" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {}}
                  >
                    <DeleteOutline sx={{ color: "alt.main" }} />
                  </IconButton>
                </Box>
              </Box>
              {/* Body */}
              <Box
                sx={{
                  p: "20px",
                }}
              >
                {/* Details */}
                <Box
                  sx={{
                    p: "10px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    À propos
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "70px",
                      rowGap: "20px",
                      padding: "10px",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <TextItem
                      title="Nom de l'événement"
                      subTitle={event.name}
                    />
                    <TextItem
                      title="Type"
                      subTitle={getEventTypeLabel(event.type)}
                    />
                    <TextItem
                      title="Type de lieu"
                      subTitle={getEventPlaceTypeLabel(event.place_type)}
                    />
                    <TextItem
                      title="Dete de debut"
                      subTitle={formatDate(event.start_date)}
                    />
                    <TextItem
                      title="Dete de fin"
                      subTitle={formatDate(event.end_date)}
                    />
                    <TextItem
                      title="Description"
                      subTitle={event.description}
                    />
                  </Box>
                </Box>
                <Builder
                  builder={() => {
                    if (event.type === "private") {
                      return (
                        <Box
                          sx={{
                            mt: "20px",
                            p: "10px",
                            border: "1px solid black",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "grey",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              À propos de l'organisateur
                            </Typography>
                            <CustomButton
                              text={"Voir plus"}
                              sx={{
                                bgcolor: "blue",
                                p: "2px 10px",
                              }}
                              onClick={() =>
                                navigate(`/companies/${event.organized_by._id}`)
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "70px",
                              rowGap: "20px",
                              padding: "10px",
                              alignItems: "start",
                              justifyContent: "start",
                            }}
                          >
                            <TextItem
                              title="Nom de société"
                              subTitle={event.organized_by.name}
                            />
                            <TextItem
                              title="Adresse"
                              subTitle={event.organized_by.address}
                            />
                            <TextItem
                              title="Email"
                              subTitle={event.organized_by.email}
                            />
                            <TextItem
                              title="Description"
                              subTitle={event.organized_by.description}
                            />
                            <TextItem
                              title="Téléphone"
                              subTitle={event.organized_by.phone}
                            />
                          </Box>
                        </Box>
                      );
                    }
                  }}
                />
                <Builder
                  builder={() => {
                    if (event.place_type === "place") {
                      return <EventPlaceDetails eventId={event._id} />;
                    }
                  }}
                />
                {/* games and participents */}
                <Box
                  sx={{
                    height: "45vh",
                    display: "flex",
                    mt: "20px",
                    gap: "10px",
                  }}
                >
                  {/* games */}
                  <EventGamesList eventId={event._id} />
                  {/* prticipents */}
                  <EventParticipentsList eventId={event._id} />
                </Box>
              </Box>
              <PopUp open={userDetailsOpen} setOpen={setUserDetailsOpen}>
                <UserDetails
                  user={selectedUser}
                  setSelectedUser={setSelectedUser}
                  setOpen={setUserDetailsOpen}
                />
              </PopUp>
            </Box>
          );
        } else {
          return <ErrorText text={"Evénement non trouvé"} />;
        }
      }}
    />
  );
};

export default EventDetails;
