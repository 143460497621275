import { Button, TableCell, TableRow } from "@mui/material";
import { formatDate } from "helpers/format_date";
import React from "react";
import { useNavigate } from "react-router-dom";

const ReservationItem = ({ reservation }) => {
  const navigate = useNavigate();
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        ":hover": {
          boxShadow: 3,
        },
        cursor: "pointer",
      }}
      onClick={() => navigate(`/reservations/${reservation._id}`)}
    >
      <TableCell>{reservation.place.name}</TableCell>
      <TableCell>{reservation.place.address}</TableCell>
      <TableCell>{formatDate(reservation.start_date)}</TableCell>
      <TableCell>{formatDate(reservation.end_date)}</TableCell>
    </TableRow>
  );
};

export default ReservationItem;
