import { Box, Button, Typography } from "@mui/material";
import GameCard from "Components/Admin/Games/GameCard";
import { gamesDataList, gamesList } from "Pages/data";
import React from "react";
import { useNavigate } from "react-router-dom";

const GamesList = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "20px 10px",
          // position: "sticky",
          // top: "0",
          // zIndex: "1",
        }}
      >
        <Typography variant="h3" fontWeight="bold" color="secondary">
          List des jeux
        </Typography>
        <Button
          sx={{
            width: "100px",
            fontSize: "16px",
            fontWeight: "400",
            backgroundColor: "alt.main",
            textTransform: "none",
            ":hover": {
              backgroundColor: "alt[400]",
            },
          }}
          onClick={() => navigate("/games/add")}
        >
          Ajouter
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {gamesDataList.map((game) => (
          <GameCard key={game._id} game={game} />
        ))}
      </Box>
    </>
  );
};

export default GamesList;
