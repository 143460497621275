import React from "react";
import { Route, Routes } from "react-router-dom";
import EventsList from "./EventsList";
import AddEditEvent from "./AddEditEvent";
import EventDetails from "./EventDetails";
import { Box } from "@mui/material";

const Events = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Routes>
        <Route path="/" element={<EventsList />} />
        <Route path="/add" element={<AddEditEvent />} />
        <Route path="/:eventId/*">
          <Route path="" element={<EventDetails />} />
          <Route path="edit" element={<AddEditEvent />} />
        </Route>
      </Routes>
    </Box>
  );
};

export default Events;
