import { Box, Button, Typography } from "@mui/material";
import MoocCard from "Components/Admin/Moocs/MoocCard";
import { moocsDataList } from "Pages/data";
import React from "react";
import { useNavigate } from "react-router-dom";

const MoocsList = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "20px 10px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" color="secondary">
          List des Moocs
        </Typography>
        <Button
          sx={{
            width: "100px",
            fontSize: "16px",
            fontWeight: "400",
            backgroundColor: "alt.main",
            textTransform: "none",
            ":hover": {
              backgroundColor: "alt[400]",
            },
          }}
          onClick={() => navigate("/moocs/add")}
        >
          Ajouter
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {moocsDataList.map((mooc) => (
          <MoocCard key={mooc._id} mooc={mooc} />
        ))}
      </Box>
    </>
  );
};

export default MoocsList;
