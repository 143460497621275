import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  BorderColorOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Builder from "Components/Builder";
import ErrorText from "Components/ErrorText";
import PopUp from "Components/Popup";
import { placesDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserDetails from "../Users/UserDetails";
import PlaceGamesList from "Components/Places/PlaceGamesList";
import PlaceReservationList from "Components/Places/PlaceReservationList";
import TextItem from "Components/TextItem";

const PlaceDetails = () => {
  const navigate = useNavigate();
  const { placeId } = useParams();
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [place, setPlace] = useState(null);
  useEffect(() => {
    const data = placesDataList.find((item) => item._id == placeId);
    setPlace(data);
  }, [placeId]);
  return (
    <Builder
      builder={() => {
        if (place) {
          return (
            <Box>
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    startIcon={
                      <ArrowBackOutlined
                        sx={{
                          color: "grey",
                          height: "15px",
                          width: "18px",
                        }}
                      />
                    }
                    sx={{
                      color: "grey",
                      textTransform: "none",
                      fontSize: "14px",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Retour
                  </Button>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={"secondary.light"}
                  >
                    {place.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {
                      navigate(`edit`);
                    }}
                  >
                    <BorderColorOutlined sx={{ color: "alt.main" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {}}
                  >
                    <DeleteOutline sx={{ color: "alt.main" }} />
                  </IconButton>
                </Box>
              </Box>
              {/* Body */}
              <Box
                sx={{
                  p: "20px",
                }}
              >
                {/* Details */}
                <Box
                  sx={{
                    p: "10px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    À propos
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "70px",
                      rowGap: "20px",
                      padding: "10px",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <TextItem title="Nom de lieu" subTitle={place.name} />
                    <TextItem title="Adresse" subTitle={place.address} />
                    <TextItem title="Email" subTitle={place.email} />
                    <TextItem
                      title="Description"
                      subTitle={place.description}
                    />
                    <TextItem title="Capacité" subTitle={place.capacity} />
                    {place.paying && (
                      <TextItem title="Prix par jour" subTitle={place.price} />
                    )}
                  </Box>
                </Box>
                {/* games and reservations */}
                <Box
                  sx={{
                    height: "45vh",
                    display: "flex",
                    mt: "20px",
                    gap: "10px",
                  }}
                >
                  {/* games */}
                  <PlaceGamesList placeId={place._id} />
                  {/* Reservations */}
                  <PlaceReservationList placeId={place._id} />
                </Box>
              </Box>
              <PopUp open={userDetailsOpen} setOpen={setUserDetailsOpen}>
                <UserDetails
                  user={selectedUser}
                  setSelectedUser={setSelectedUser}
                  setOpen={setUserDetailsOpen}
                />
              </PopUp>
            </Box>
          );
        } else {
          return <ErrorText text={"Lieu n'existe pas"} />;
        }
      }}
    />
  );
};

export default PlaceDetails;
