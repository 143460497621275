import { MenuOutlined } from "@mui/icons-material";

import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Box,
  Button,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import NavBar from "./NavBar";
import { LoginOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";

function NavItem({ text, link, color }) {
  return (
    <a href={`#${link}`} style={{ textDecoration: "none" }}>
      <Typography
        sx={{
          color: color,
          fontSize: "16px",
          fontWeight: "500",
          textTransform: "none",
          p: "5px 10px",
          textAlign: "start",
        }}
      >
        {text}
      </Typography>
    </a>
  );
}

function TopBar() {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navItems = [
    { text: "Bienvenue", link: "welcome" },
    { text: "Services", link: "services" },
    { text: "À propos", link: "about" },
    { text: "Contact", link: "contact" },
  ];
  const getColor = (item, index) => {
    const links = navItems.map((item) => item.link);
    if (index === 0 && !links.includes(window.location.href.split("#")[1])) {
      return "tertiary.main";
    }
    if (window.location.href.includes(item.link)) {
      return "tertiary.main";
    }
    return "primary.main";
  };
  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        bgcolor: "secondary.main",
        padding: "5px",
        position: "fixed",
        zIndex: "1",
      }}
    >
      {/* Navigation */}
      <AppBar component="nav">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuOutlined />
          </IconButton>
          <Typography
            sx={{
              color: "tertiary.main",
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            Prêt à jouer
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item, index) => (
                <Button key={item} sx={{ color: "#fff" }}>
                  <NavItem
                    key={index}
                    text={item.text}
                    link={item.link}
                    index={index}
                    color={getColor(item, index)}
                  />
                </Button>
              ))}
            </Box>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "black",
                width: "147px",
                height: "40px",
                border: "none",
                borderRadius: "6px",
                textTransform: "none",
                fontSize: "15px",
                fontWeight: "600",
                display: { xs: "none", sm: "inherit" },
              }}
              startIcon={
                <LoginOutlined
                  sx={{
                    color: "black",
                    fontSize: "20px",
                    mr: "15px",
                  }}
                />
              }
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "200px",
            },
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "tertiary.main",
                fontSize: "24px",
                fontWeight: "600",
                p: "10px",
              }}
            >
              Prêt à jouer
            </Typography>
            <Divider />
            <List>
              {navItems.map((item, index) => (
                <NavItem
                  key={index}
                  text={item.text}
                  link={item.link}
                  index={index}
                  color={getColor(item, index)}
                />
              ))}
              <Button
                sx={{
                  backgroundColor: "primary.main",
                  color: "black",
                  width: "147px",
                  height: "35px",
                  border: "none",
                  borderRadius: "6px",
                  textTransform: "none",
                  fontSize: "15px",
                  fontWeight: "600",
                  mt: "20px",
                }}
                startIcon={
                  <LoginOutlined
                    sx={{
                      color: "black",
                      fontSize: "20px",
                      mr: "15px",
                    }}
                  />
                }
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </List>
          </Box>
        </Drawer>
      </nav>
      {/* Login Button */}
    </Box>
  );
}

export default TopBar;
