import { Button, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const CompanyItem = ({ company }) => {
  const navigate = useNavigate();
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        ":hover": {
          boxShadow: 3,
        },
        cursor: "pointer",
      }}
      onClick={() => navigate(`/companies/${company._id}`)}
    >
      <TableCell>{company.name}</TableCell>
      <TableCell>{company.email}</TableCell>
      <TableCell>{company.phone}</TableCell>
      <TableCell>{company.address}</TableCell>
      <TableCell
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
        align="center"
      >
        <Button
          variant="outlined"
          sx={{
            color: "grey",
            borderColor: "grey",
            border: "1px solid",
            textTransform: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/companies/${company._id}/edit`);
          }}
        >
          Modifier
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "1px solid",
            color: "grey",
            borderColor: "grey",
            textTransform: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            // handleDelete(company._id);
          }}
        >
          Supprimer
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default CompanyItem;
