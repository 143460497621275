import {
  ArrowBackOutlined,
  BorderColorOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Builder from "Components/Builder";
import ErrorText from "Components/ErrorText";
import { gamesDataList, placesDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextItem from "Components/TextItem";
import GamePlacesList from "Components/Admin/Games/GamePlacesList";
import GameEventsList from "Components/Admin/Games/GameEventsList";

const GamesDetails = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const [game, setGame] = useState(null);
  const getGameLevelLabel = (level) => {
    const levelLabels = {
      easy: "Facile",
      medium: "Moyen",
      hard: "Difficile",
      expert: "Expert",
    };
    return levelLabels[level] || "Facile";
  };
  useEffect(() => {
    const data = gamesDataList.find((item) => item._id == gameId);
    setGame(data);
  }, [gameId]);
  return (
    <Builder
      builder={() => {
        if (game) {
          return (
            <Box>
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    startIcon={
                      <ArrowBackOutlined
                        sx={{
                          color: "grey",
                          height: "15px",
                          width: "18px",
                        }}
                      />
                    }
                    sx={{
                      color: "grey",
                      textTransform: "none",
                      fontSize: "14px",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Retour
                  </Button>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={"secondary.light"}
                  >
                    {game.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {
                      navigate(`edit`);
                    }}
                  >
                    <BorderColorOutlined sx={{ color: "alt.main" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {}}
                  >
                    <DeleteOutline sx={{ color: "alt.main" }} />
                  </IconButton>
                </Box>
              </Box>
              {/* Body */}
              <Box
                sx={{
                  p: "20px",
                }}
              >
                {/* Details */}
                <Box
                  sx={{
                    p: "10px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    À propos
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "70px",
                      rowGap: "20px",
                      padding: "10px",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <TextItem title="Nom" subTitle={game.name} />
                    <TextItem
                      title="Niveau"
                      subTitle={getGameLevelLabel(game.level)}
                    />
                    <TextItem title="Description" subTitle={game.description} />
                    <TextItem
                      title="Prix"
                      subTitle={game.price ? game.price : "Gratuit"}
                    />
                  </Box>
                </Box>
                {/* games and reservations */}
                <Box
                  sx={{
                    height: "45vh",
                    display: "flex",
                    mt: "20px",
                    gap: "10px",
                  }}
                >
                  {/* games */}
                  <GamePlacesList gameId={game._id} />
                  {/* Evénément */}
                  <GameEventsList gameId={game._id} />
                </Box>
              </Box>
            </Box>
          );
        } else {
          return <ErrorText text={"Jeu n'existe pas"} />;
        }
      }}
    />
  );
};

export default GamesDetails;
