import React from "react";
import { Box, Typography } from "@mui/material";
import bg from "Images/1.jpg";

function Welcome() {
  return (
    <Box
      id="welcome"
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        sx={{
          color: "secondary.main",
          fontSize: "50px",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Prêt à jouer
      </Typography>
      <Typography
        sx={{
          color: "secondary.light",
          fontSize: "25px",
          fontWeight: "500",
          mt: "10px",
          textAlign: "center",
        }}
      >
        Votre destination pour tout l'univers du jeu.
      </Typography>
      <Typography
        sx={{
          color: "secondary.main",
          fontSize: "20px",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Profiter de nos services
      </Typography>
    </Box>
  );
}

export default Welcome;
