import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import LessonsList from "./LessonsList";
import AddEditLesson from "./AddEditLesson";
import LessonDetails from "./LessonDetails";
import NotFoundError from "Components/NotFoundError";

const Lessons = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Routes>
        <Route path="/" element={<LessonsList />} />
        <Route path="/add" element={<AddEditLesson />} />
        <Route path="/:lessonId/edit" element={<AddEditLesson />} />
        <Route path="/:lessonId" element={<LessonDetails />} />
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Box>
  );
};

export default Lessons;
