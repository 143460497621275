import { Box, Typography } from "@mui/material";
import Builder from "Components/Builder";
import CustomUsersList from "Components/CustomUsersList";
import ErrorText from "Components/ErrorText";
import LoadingShimmer from "Components/LoadingShimmer";
import PopUp from "Components/Popup";
import UserDetails from "Pages/Admin/Users/UserDetails";
import { eventsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";

const EventParticipentsList = ({ eventId }) => {
  const [participents, setParticipents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  useEffect(() => {
    const data = eventsDataList.find((item) => item._id == eventId);
    if (data) {
      setParticipents(data.participents);
    }
  }, [eventId]);
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "6px",
        pt: "20px",
        flex: "1",
        overflowY: "auto",
      }}
    >
      <Typography
        fontSize="20px"
        fontWeight="bold"
        color={"grey"}
        sx={{
          pl: "15px",
        }}
      >
        Prticipents
      </Typography>
      <Builder
        builder={() => {
          if (isLoading) {
            return <LoadingShimmer />;
          }
          if (participents.length > 0) {
            return (
              <CustomUsersList
                users={participents}
                onUserClick={(item) => {
                  setSelectedUser(item);
                  setUserDetailsOpen(true);
                }}
              />
            );
          } else {
            return (
              <ErrorText
                text="Aucun participent trouvé !"
                sx={{ height: "80%" }}
              />
            );
          }
        }}
      />
      <PopUp open={userDetailsOpen} setOpen={setUserDetailsOpen}>
        <UserDetails
          user={selectedUser}
          setSelectedUser={setSelectedUser}
          setOpen={setUserDetailsOpen}
        />
      </PopUp>
    </Box>
  );
};

export default EventParticipentsList;
