import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Builder from "Components/Builder";
import CustomText from "Components/CustomText";
import ErrorText from "Components/ErrorText";
import LoadingShimmer from "Components/LoadingShimmer";
import { placesDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const GamePlacesList = ({ gameId }) => {
  const navigate = useNavigate();
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const data = placesDataList.filter((item) =>
      item.games.map((game) => game._id).includes(gameId)
    );
    if (data) {
      setPlaces(data);
    }
  }, [gameId]);
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "6px",
        pt: "20px",
        flex: "1",
        overflowY: "auto",
      }}
    >
      <Typography
        fontSize="20px"
        fontWeight="bold"
        color={"grey"}
        sx={{
          pl: "15px",
        }}
      >
        Lieux
      </Typography>
      <Builder
        builder={() => {
          if (isLoading) {
            return <LoadingShimmer />;
          }
          if (places.length > 0) {
            return (
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    color={"grey"}
                    fontSize={"16px"}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    m="10px"
                    p="0 30px"
                  >
                    <Typography variant="h6">Nom</Typography>
                    <Typography variant="h6">Capacité</Typography>
                  </Box>
                  <Divider
                    sx={{
                      textAlign: "center",
                      height: "2px",
                      width: "100%",
                      backgroundColor: "black",
                    }}
                  />
                </Box>
                <Box>
                  <List>
                    {places.map((item) => (
                      <Box key={item._id}>
                        <ListItem
                          sx={{
                            padding: "10px 30px",
                            ":hover": {
                              boxShadow: 3,
                            },
                            cursor: "pointer",
                          }}
                          disablePadding
                          secondaryAction={
                            <Typography
                              variant="h6"
                              sx={{
                                color: "secondary.main",
                                textAlign: "start",
                                ml: "-50px",
                              }}
                            >
                              {item.capacity}
                            </Typography>
                          }
                          onClick={() => navigate(`/places/${item._id}`)}
                        >
                          <ListItemText
                            id="worker-label-id"
                            sx={{ color: "black" }}
                            primaryTypographyProps={{
                              style: {
                                color: "secondary.main",
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                            }}
                            secondaryTypographyProps={{
                              style: {
                                color: "grey",
                                fontSize: "14px",
                              },
                            }}
                            primary={item.name}
                            secondary={
                              <CustomText
                                text={item.address}
                                sx={{ fontSize: "12px", color: "grey" }}
                              />
                            }
                          />
                        </ListItem>
                        <Divider
                          sx={{
                            textAlign: "center",
                            height: "1px",
                            backgroundColor: "black",
                            m: "0 10px",
                          }}
                        />
                      </Box>
                    ))}
                  </List>
                </Box>
              </Box>
            );
          } else {
            return (
              <ErrorText text="Aucun Lieu trouvé !" sx={{ height: "80%" }} />
            );
          }
        }}
      />
    </Box>
  );
};

export default GamePlacesList;
