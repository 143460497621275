import React from "react";
import { Box, Typography } from "@mui/material";
import bg2 from "Images/2.jpg";
import bg3 from "Images/3.jpg";
import bg4 from "Images/4.jpg";
import bg5 from "Images/5.jpg";
import bg6 from "Images/6.jpg";
import bg from "Images/t1.jpg";

const ServiceCard = ({ service }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        width: "200px",
        height: "250px",
        padding: "5px",
      }}
    >
      <Box
        sx={{
          height: "70px",
          width: "70px",
          backgroundImage: `url(${service.image})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          borderRadius: "35px",
        }}
      />
      <Typography
        sx={{
          color: "secondary.main",
          fontSize: "18px",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {service.title}
      </Typography>
      <Typography
        sx={{
          color: "grey",
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        {service.description.length > 100
          ? service.description.substring(0, 100)
          : service.description}
      </Typography>
    </Box>
  );
};

function Services() {
  const isMobilePhone = window.innerWidth < 600;
  const services = [
    {
      title: "Vente de Jeux en Ligne",
      description:
        "Trouvez une large sélection de jeux pour tous les goûts et tous les âges.",
      image: bg2,
    },
    {
      title: "Formation en Ligne (MOOC)",
      description:
        "Apprenez à jouer et maîtriser vos jeux préférés avec nos cours en ligne adaptés à tous les niveaux.",
      image: bg3,
    },
    {
      title: "Organisation d'Événements",
      description:
        "Des événements personnalisés, privés ou publics, pour vivre des expériences uniques.",
      image: bg4,
    },
    {
      title: "Tournois & Compétitions",
      description:
        "Affrontez d'autres joueurs dans nos tournois avec des prix à la clé.",
      image: bg5,
    },
    {
      title: "Espace Communautaire",
      description:
        "Rejoignez notre communauté pour échanger et partager vos expériences autour des jeux.",
      image: bg6,
    },
  ];
  return (
    <Box
      sx={{
        height: isMobilePhone ? "100%" : "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",

        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      id="services"
    >
      <Typography
        sx={{
          color: "secondary.main",
          fontWeight: "bold",
          fontSize: "2rem",
          textAlign: "left",
          margin: "60px 10px 10px 10px",
          alignSelf: "start",
        }}
      >
        Nos Services
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px",
          width: "60%",
        }}
      >
        {services.map((item, index) => (
          <ServiceCard key={index} service={item} />
        ))}
      </Box>
    </Box>
  );
}

export default Services;
