import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import OrderItem from "Components/Admin/Orders/OrderItem";
import { ordersDataList } from "Pages/data";
import React from "react";

const OrdersList = () => {
  return (
    <>
      <Typography variant="h3" fontWeight="bold" color="secondary" p={"20px"}>
        List des commandes
      </Typography>
      <Box
        sx={{
          ".css-r340ef-MuiTableCell-root": {
            color: "grey",
          },
          ".css-l5s4qo-MuiTableCell-root": {
            color: "grey",
          },
        }}
      >
        {/* table */}
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "primary.main",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Numéro d'ordre</TableCell>
                <TableCell>Nombre de jeux</TableCell>
                <TableCell>Prix total</TableCell>
                <TableCell>Réduction</TableCell>
                <TableCell>Prix aprés reduction</TableCell>
                <TableCell>Etat</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "grey",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordersDataList.map((order) => (
                <OrderItem key={order._id} order={order} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default OrdersList;
