import React from "react";
import { Route, Routes } from "react-router-dom";
import GamesList from "./GamesList";
import AddEditGame from "./AddEditGame";
import GameDetails from "./GameDetails";
import { Box } from "@mui/material";

const Games = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Routes>
        <Route path="/" element={<GamesList />} />
        <Route path="/add" element={<AddEditGame />} />
        <Route path="/:gameId/*">
          <Route path="" element={<GameDetails />} />
          <Route path="edit" element={<AddEditGame />} />
        </Route>
      </Routes>
    </Box>
  );
};

export default Games;
