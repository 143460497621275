import React from "react";
import TopBar from "./TopBar";
import Welcome from "./Welcome";
import Services from "./Services";
import Contact from "./Contact";
import Footer from "./Footer";
import { Box } from "@mui/material";
import About from "./About";
function LandingPage() {
  return (
    <Box>
      <TopBar />
      <Welcome />
      <Services />
      <About />
      <Contact />
      <Footer />
    </Box>
  );
}

export default LandingPage;
