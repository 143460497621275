import { ArrowBackOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import Builder from "Components/Builder";
import CustomText from "Components/CustomText";
import ErrorText from "Components/ErrorText";
import { moocsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const MoocDetails = () => {
  const navigate = useNavigate();
  const { moocId } = useParams();
  const [mooc, setMooc] = useState(null);
  useEffect(() => {
    const data = moocsDataList.find((mooc) => mooc._id == moocId);
    setMooc(data);
  }, []);
  return (
    <Builder
      builder={() => {
        if (mooc) {
          return (
            <Box>
              <Button
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  textTransform: "none",
                  m: "10px",
                  color: "black",
                }}
                startIcon={<ArrowBackOutlined style={{ color: "black" }} />}
                onClick={() => navigate("/moocs")}
              >
                Retour
              </Button>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: 2,
                  padding: "20px",
                  width: "50%",
                }}
              >
                <CustomText
                  sx={{
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                  text={mooc.title}
                />
                <CustomText
                  sx={{
                    fontSize: "14px",
                    color: "grey",
                    padding: "10px",
                  }}
                  text={mooc.description}
                />
                <Button
                  sx={{
                    bgcolor: "alt.main",
                    fontSize: "14px",
                    fontWeight: "400",
                    textTransform: "none",
                    padding: "5px 15px",
                  }}
                  onClick={() => navigate(`/moocs/${moocId}/lessons`)}
                >
                  Consulter les leçons
                </Button>
              </Box>
            </Box>
          );
        } else {
          return <ErrorText text={"Mooc n'existe pas"} />;
        }
      }}
    />
  );
};

export default MoocDetails;
