import { Box, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import CustomText from "./CustomText";
import { useNavigate } from "react-router-dom";

const CustomGamesList = ({ games }) => {
  const navigate = useNavigate();
  const getGameLevelLabel = (level) => {
    const labels = {
      easy: "Facile",
      medium: "Moyen",
      hard: "Difficile",
      expert: "Expert",
    };
    return labels[level] || "Facile";
  };
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          color={"grey"}
          fontSize={"16px"}
          width="100%"
          display="flex"
          justifyContent="space-between"
          m="10px"
          p="0 30px"
        >
          <Typography variant="h6">Nom</Typography>
          <Typography variant="h6">Prix</Typography>
        </Box>
        <Divider
          sx={{
            textAlign: "center",
            height: "2px",
            width: "100%",
            backgroundColor: "black",
          }}
        />
      </Box>
      <Box>
        <List>
          {games.map((item) => (
            <Box key={item._id}>
              <ListItem
                sx={{
                  padding: "10px 30px",
                  ":hover": {
                    boxShadow: 3,
                  },
                  cursor: "pointer",
                }}
                disablePadding
                secondaryAction={
                  <Typography
                    variant="h6"
                    sx={{
                      color: "secondary.main",
                      textAlign: "start",
                      ml: "-50px",
                    }}
                  >
                    {item.price || "Gratuit"}
                  </Typography>
                }
                onClick={() => navigate(`/games/${item._id}`)}
              >
                <ListItemText
                  id="worker-label-id"
                  sx={{ color: "black" }}
                  primaryTypographyProps={{
                    style: {
                      color: "secondary.main",
                      fontSize: "14px",
                      fontWeight: "500",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: {
                      color: "grey",
                      fontSize: "14px",
                    },
                  }}
                  primary={`${item.name}`}
                  secondary={
                    <CustomText
                      text={getGameLevelLabel(item.level)}
                      sx={{ fontSize: "12px", color: "grey" }}
                    />
                  }
                />
              </ListItem>
              <Divider
                sx={{
                  textAlign: "center",
                  height: "1px",
                  backgroundColor: "black",
                  m: "0 10px",
                }}
              />
            </Box>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default CustomGamesList;
