import { MenuOutlined } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";

function NavItem({ text, link, color }) {
  return (
    <a href={`#${link}`} style={{ textDecoration: "none" }}>
      <Typography
        sx={{
          color: color,
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {text}
      </Typography>
    </a>
  );
}

function NavBar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navItems = [
    { text: "Bienvenue", link: "welcome" },
    { text: "Services", link: "services" },
    { text: "À propos", link: "about" },
    { text: "Contact", link: "contact" },
  ];
  const getColor = (item, index) => {
    const links = navItems.map((item) => item.link);
    if (index === 0 && !links.includes(window.location.href.split("#")[1])) {
      return "tertiary.main";
    }
    if (window.location.href.includes(item.link)) {
      return "tertiary.main";
    }
    return "primary.main";
  };
  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    // <Box
    //   sx={{
    //     width: "33%",
    //     display: "flex",
    //     justifyContent: "space-around",
    //   }}
    // >
    //   {navItems.map((item, index) => (
    //     <NavItem
    //       key={index}
    //       text={item.text}
    //       link={item.link}
    //       index={index}
    //       color={getColor(item, index)}
    //     />
    //   ))}
    // </Box>
    <>
      
    </>
  );
}

export default NavBar;
