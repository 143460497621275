import { useReducer } from 'react';
import {
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_ERROR,
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
} from '../Actions/UsersActions';

const initialState = {
    isLoading: false,
    isSignOut: false,
    userToken: null,
    user: {},
    users: [],
    error: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        // Fetch all users
        case FETCH_USERS:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload,
                error: null,
            };
        case FETCH_USERS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        // Fetch single user by ID
        case FETCH_USER:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload,
                error: null,
            };
        case FETCH_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        // Update user
        case UPDATE_USER:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload,  // Assume the updated user data is returned
                error: null,
            };
        case UPDATE_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        // Delete user
        case DELETE_USER:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: state.users.filter(user => user._id !== action.payload),  // Remove the deleted user
                error: null,
            };
        case DELETE_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        // Register user (Admin, Client, Company Admin)
        case REGISTER_USER:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: [...state.users, action.payload],  // Add the new user to the list
                error: null,
            };
        case REGISTER_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default userReducer;
