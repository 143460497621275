import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

const CustomUsersList = ({ users, onUserClick }) => {
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          color={"grey"}
          fontSize={"16px"}
          width="100%"
          display="flex"
          justifyContent="space-between"
          m="10px"
          p="0 30px"
        >
          <Typography variant="h6">Nom</Typography>
          <Typography variant="h6">Téléphone</Typography>
        </Box>
        <Divider
          sx={{
            textAlign: "center",
            height: "2px",
            width: "100%",
            backgroundColor: "black",
          }}
        />
      </Box>
      <List>
        {users.map((item) => (
          <Box key={item._id}>
            <ListItem
              sx={{
                padding: "10px 30px",
                ":hover": {
                  boxShadow: 3,
                },
                cursor: "pointer",
              }}
              disablePadding
              secondaryAction={
                <Typography
                  variant="h6"
                  sx={{
                    color: "secondary.main",
                    textAlign: "start",
                    ml: "-50px",
                  }}
                >
                  {item.phone}
                </Typography>
              }
              onClick={() => onUserClick(item)}
            >
              <ListItemText
                id="worker-label-id"
                sx={{ color: "black" }}
                primaryTypographyProps={{
                  style: {
                    color: "secondary.main",
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                }}
                secondaryTypographyProps={{
                  style: {
                    color: "grey",
                    fontSize: "14px",
                  },
                }}
                primary={`${item.first_name} ${item.last_name}`}
                secondary={item.email}
              />
            </ListItem>
            <Divider
              sx={{
                textAlign: "center",
                height: "1px",
                backgroundColor: "black",
                m: "0 10px",
              }}
            />
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default CustomUsersList;
