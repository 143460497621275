import {
  getAllUser,
  getUser,
  updateMe,
  updateUser,
  deleteUser,
  registerAdmin,
  registerClient,
  registerCompanyAdmin,
} from "../../Services/Users.services";

// Types d'action pour la gestion des utilisateurs
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

// Action pour obtenir tous les utilisateurs
export const fetchAllUsers = () => async (dispatch) => {
  dispatch({ type: FETCH_USERS });

  try {
    const result = await getAllUser();
    dispatch({ type: FETCH_USERS_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: FETCH_USERS_ERROR, payload: error.message });
  }
};

// Action pour obtenir les détails d'un utilisateur par ID
export const fetchUser = (id) => async (dispatch) => {
  dispatch({ type: FETCH_USER });

  try {
    const result = await getUser(id);
    dispatch({ type: FETCH_USER_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: FETCH_USER_ERROR, payload: error.message });
  }
};

// Action pour mettre à jour les informations d'un utilisateur connecté
export const updateConnectedUser = (userData) => async (dispatch) => {
  dispatch({ type: UPDATE_USER });

  try {
    const result = await updateMe(userData);
    dispatch({ type: UPDATE_USER_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: UPDATE_USER_ERROR, payload: error.message });
  }
};

// Action pour mettre à jour un utilisateur par ID (pour les administrateurs)
export const updateUserById = (id, userData) => async (dispatch) => {
  dispatch({ type: UPDATE_USER });

  try {
    const result = await updateUser(id, userData);
    dispatch({ type: UPDATE_USER_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: UPDATE_USER_ERROR, payload: error.message });
  }
};

// Action pour supprimer un utilisateur par ID (pour les administrateurs)
export const removeUser = (id) => async (dispatch) => {
  dispatch({ type: DELETE_USER });

  try {
    const result = await deleteUser(id);
    dispatch({ type: DELETE_USER_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: DELETE_USER_ERROR, payload: error.message });
  }
};

// Action pour enregistrer un administrateur
export const registerNewAdmin = (userData) => async (dispatch) => {
  dispatch({ type: REGISTER_USER });

  try {
    const result = await registerAdmin(userData);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: REGISTER_USER_ERROR, payload: error.message });
  }
};

// Action pour enregistrer un client
export const registerNewClient = (userData) => async (dispatch) => {
  dispatch({ type: REGISTER_USER });

  try {
    const result = await registerClient(userData);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: REGISTER_USER_ERROR, payload: error.message });
  }
};

// Action pour enregistrer un administrateur d'entreprise
export const registerNewCompanyAdmin = (userData) => async (dispatch) => {
  dispatch({ type: REGISTER_USER });

  try {
    const result = await registerCompanyAdmin(userData);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: REGISTER_USER_ERROR, payload: error.message });
  }
};
