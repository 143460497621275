import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import CompaniesList from "./CompaniesList";
import AddEditCompany from "./AddEditCompany";
import CompanyDetails from "./CompanyDetails";
import NotFoundError from "Components/NotFoundError";

const Companies = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Routes>
        <Route path="/" element={<CompaniesList />} />
        <Route path="/add" element={<AddEditCompany />} />
        <Route path="/:companyId/edit" element={<AddEditCompany />} />
        <Route path="/:companyId" element={<CompanyDetails />} />
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Box>
  );
};

export default Companies;
