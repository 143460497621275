import React, { useState } from 'react';
import "./Login.css";
import eyeSlash from '../../Images/eye-slash.svg';
import eye from '../../Images/eye.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../Redux/Actions/AuthentificationActions';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email obligatoire'),
    password: Yup.string().required('Mot de passe obligatoire'),
});

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [passwordHidden, setPasswordHidden] = useState(true);
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleLogin = async (values) => {
        await dispatch(login(values)).then(result => {
            if (result) {
                navigate("/");
            } else {
                setOpen(true);
            }
        }).catch(error => {
            console.error('Erreur lors de la connexion', error);
        });
    };

    return (
        <div className="container-fluid LoginPagecontainer">
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Nom d'utilisateur ou mot de passe incorrect
                </Alert>
            </Snackbar>
            <div className="row">
                <div className="col-md-6 login-left">
                    <h2>Bienvenue!</h2>
                    <h2>Pret à jouer</h2>
                </div>
                <div className="col-md-6 login-right">
                    <div className="loginbody">
                        <h4>Login</h4>
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleLogin}
                        >
                            {({
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                values,
                                errors,
                                touched,
                            }) => (
                                <div className="form-containerlogin">
                                    <div className="form-group">
                                        <label htmlFor="email" className="label-login">Nom d'utilisateur</label>
                                        <input
                                            type="text"
                                            placeholder="Nom d'utilisateur"
                                            className="input-field-login"
                                            id="email"
                                            name="email"
                                            onChange={handleChange("email")}
                                            onBlur={handleBlur("email")}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && (
                                            <span className="errorText">{errors.email}</span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password" className="label-login">Mot de passe</label>
                                        <div className="password-input-container">
                                            <input
                                                type={passwordHidden ? 'password' : 'text'}
                                                placeholder='Mot de passe'
                                                className="input-field-login"
                                                id="password"
                                                name="password"
                                                onChange={handleChange("password")}
                                                onBlur={handleBlur("password")}
                                                value={values.password}
                                            />
                                            <div
                                                className="password-toggle-icon"
                                                onClick={() => setPasswordHidden(!passwordHidden)}
                                            >
                                                <img src={passwordHidden ? eyeSlash : eye} alt="icon" />
                                            </div>
                                        </div>
                                        {errors.password && touched.password && (
                                            <span className="errorText">{errors.password}</span>
                                        )}
                                    </div>
                                    <button type="submit" onClick={handleSubmit} className="button-login">Se connecter</button>
                                    <Link to='/forgetPassword' className="login-password-link">
                                        Mot de passe oublié?
                                    </Link>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
