// actions/gameActions.js

import {
    getAllGames,
    getGameDetails,
    createGame,
    updateGameDetails,
    deleteGame,
  } from "../../Services/Games.services";
  
  // Action Types
  export const FETCH_GAMES_REQUEST = "FETCH_GAMES_REQUEST";
  export const FETCH_GAMES_SUCCESS = "FETCH_GAMES_SUCCESS";
  export const FETCH_GAMES_FAILURE = "FETCH_GAMES_FAILURE";
  
  export const FETCH_GAME_DETAILS_REQUEST = "FETCH_GAME_DETAILS_REQUEST";
  export const FETCH_GAME_DETAILS_SUCCESS = "FETCH_GAME_DETAILS_SUCCESS";
  export const FETCH_GAME_DETAILS_FAILURE = "FETCH_GAME_DETAILS_FAILURE";
  
  export const CREATE_GAME_REQUEST = "CREATE_GAME_REQUEST";
  export const CREATE_GAME_SUCCESS = "CREATE_GAME_SUCCESS";
  export const CREATE_GAME_FAILURE = "CREATE_GAME_FAILURE";
  
  export const UPDATE_GAME_REQUEST = "UPDATE_GAME_REQUEST";
  export const UPDATE_GAME_SUCCESS = "UPDATE_GAME_SUCCESS";
  export const UPDATE_GAME_FAILURE = "UPDATE_GAME_FAILURE";
  
  export const DELETE_GAME_REQUEST = "DELETE_GAME_REQUEST";
  export const DELETE_GAME_SUCCESS = "DELETE_GAME_SUCCESS";
  export const DELETE_GAME_FAILURE = "DELETE_GAME_FAILURE";
  
  
  // Fetch all games
  export const fetchAllGames = () => async (dispatch) => {
    dispatch({ type: FETCH_GAMES_REQUEST });
    try {
      const games = await getAllGames();
      dispatch({ type: FETCH_GAMES_SUCCESS, payload: games });
    } catch (error) {
      dispatch({ type: FETCH_GAMES_FAILURE, error });
    }
  };
  
  // Fetch game details by ID
  export const fetchGameDetails = (gameId) => async (dispatch) => {
    dispatch({ type: FETCH_GAME_DETAILS_REQUEST });
    try {
      const game = await getGameDetails(gameId);
      dispatch({ type: FETCH_GAME_DETAILS_SUCCESS, payload: game });
    } catch (error) {
      dispatch({ type: FETCH_GAME_DETAILS_FAILURE, error });
    }
  };
  
  // Create a new game
  export const createNewGame = (gameData, imageFile) => async (dispatch) => {
    dispatch({ type: CREATE_GAME_REQUEST });
    try {
      const newGame = await createGame(gameData, imageFile);
      dispatch({ type: CREATE_GAME_SUCCESS, payload: newGame });
    } catch (error) {
      dispatch({ type: CREATE_GAME_FAILURE, error });
      throw error;
    }
  };
  
  // Update game details
  export const updateGame = (gameId, gameData, imageFile) => async (dispatch) => {
    dispatch({ type: UPDATE_GAME_REQUEST });
    try {
      const updatedGame = await updateGameDetails(gameId, gameData, imageFile);
      dispatch({ type: UPDATE_GAME_SUCCESS, payload: updatedGame });
    } catch (error) {
      dispatch({ type: UPDATE_GAME_FAILURE, error });
    }
  };
  
  // Delete a game by ID
  export const deleteGameById = (gameId) => async (dispatch) => {
    dispatch({ type: DELETE_GAME_REQUEST });
    try {
      await deleteGame(gameId);
      dispatch({ type: DELETE_GAME_SUCCESS, payload: gameId });
    } catch (error) {
      dispatch({ type: DELETE_GAME_FAILURE, error });
    }
  };
  
  