import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Home/Hero/Hero";
import Newsletter from "./components/Home/Newsletter/Newsletter";
import Products from "./components/Products/Products";
import FooterInfo from "./components/Footer/FooterInfo";
import Footer from "./components/Footer/Footer";

const ClientHome = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Newsletter />
      <Products />
      <FooterInfo />
      <Footer />
    </div>
  );
};

export default ClientHome;
