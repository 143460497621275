import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Button,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const OrderItem = ({ order }) => {
  const [status, setStatus] = useState(order.status);
  const navigate = useNavigate();
  const eventStatusList = [
    "pending",
    "accepted",
    "refused",
    "inprogress",
    "ready",
    "delivering",
    "delivered",
    "done",
    "cancelled",
  ];
  const getOrderStatusLabel = (status) => {
    const orderStatusLabels = {
      pending: "En attente",
      accepted: "Accepté",
      refused: "Refusé",
      inprogress: "En cours",
      ready: "Prêt",
      delivering: "En cours de livraison",
      delivered: "Livré",
      done: "Terminé",
      cancelled: "Annulé",
    };
    return orderStatusLabels[status] || "En attente";
  };
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        ":hover": {
          boxShadow: 3,
        },
        cursor: "pointer",
      }}
      onClick={() => navigate(`/orders/${order._id}`)}
    >
      <TableCell>{order.order_number}</TableCell>
      <TableCell>{order.games.length}</TableCell>
      <TableCell>{order.total_price}</TableCell>
      <TableCell>{order.total_price_after_reduction ? "Oui" : "Non"}</TableCell>
      <TableCell>
        {order.total_price_after_reduction
          ? order.total_price_after_reduction
          : "-"}
      </TableCell>
      <TableCell>
        <TextField
          select
          name="status"
          value={status}
          SelectProps={{
            IconComponent: () => (
              <KeyboardArrowDown
                sx={{
                  color: "grey",
                }}
              />
            ),
            style: {
              color: "grey",
              height: "37px",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: "16px",
              color: "grey",
            },
          }}
          sx={{
            border: "1px solid",
            borderColor: "grey",
            borderRadius: "4px",
            width: "100%",
            height: "40px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          {eventStatusList?.map((item) => (
            <MenuItem
              key={item}
              sx={{
                color: "grey",
                ":hover": {
                  backgroundColor: "grey.light",
                },
              }}
              value={item}
            >
              {getOrderStatusLabel(item)}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell align="center">
        <Button
          variant="outlined"
          sx={{
            border: "1px solid",
            color: "grey",
            borderColor: "grey",
            textTransform: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            // handleDelete(order._id);
          }}
        >
          Supprimer
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default OrderItem;
