import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import UsersList from "./UsersList";
import AddEditUser from "./AddEditUser.jsx";
// import UserDetails from "./UserDetails";
import NotFoundError from "Components/NotFoundError";

const Users = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Routes>
        <Route path="/" element={<UsersList />} />
        <Route path="/add" element={<AddEditUser />} />
        <Route path="/:userId/edit" element={<AddEditUser />} />
        {/* <Route path="/:userId" element={<UserDetails />} /> */}
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Box>
  );
};

export default Users;
