import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Snackbar, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import eyeSlash from '../../Images/eye-slash.svg';
import eye from '../../Images/eye.svg';
import { useDispatch } from 'react-redux';
import { registerNewClient } from '../../Redux/Actions/UsersActions';
function Register() {
  const dispatch = useDispatch()
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().min(2, "Trop court!").max(32, "Trop long!").required('Prénom requis'),
    last_name: Yup.string().min(2, "Trop court!").max(32, "Trop long!").required('Nom de famille requis'),
    email: Yup.string().email("Email invalide").required('Email requis'),
    password: Yup.string().min(8, "Le mot de passe doit avoir au moins 8 caractères").required('Mot de passe requis'),
    phone: Yup.string().required('Téléphone requis'),
    role: Yup.string().oneOf(['CLIENT', 'COLLABORATOR', 'COMPANY']).required('Rôle requis'),
  });

  const handleRegister = async (values) => {
    try {
      const response = await dispatch(registerNewClient(values));
      // Redirige ou affiche un message de succès après l'enregistrement
    } catch (error) {
      // Assure-toi que l'erreur contient une réponse avant d'essayer d'accéder à data
      if (error.response && error.response.data && error.response.data.message) {
        console.log('Error:', error.response.data.message);  // Affiche correctement le message d'erreur
        setErrorMessage(error.response.data.message);
      } else {
        console.log('Error:', error.message);  // Affiche l'erreur si elle n'est pas liée à une réponse HTTP
        setErrorMessage('Une erreur est survenue lors de l\'enregistrement');
      }
      setOpen(true);  // Ouvre le Snackbar avec le message d'erreur
    }
  };


  const handleClose = () => setOpen(false);

  return (
    <div className="container-fluid LoginPagecontainer">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className="row">
        <div className="col-md-8 register-container">
          <div className="register-section">
            <h4>Créer un compte</h4>
            <Formik
              initialValues={{ first_name: '', last_name: '', email: '', password: '', phone: '', role: 'CLIENT' }}
              validationSchema={validationSchema}
              onSubmit={handleRegister}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <div className="form-containerlogin">
                  <div className="form-group">
                    <label htmlFor="first_name" className="label-login">Prénom</label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      className="input-field-login"
                      id="first_name"
                      name="first_name"
                      onChange={handleChange("first_name")}
                      onBlur={handleBlur("first_name")}
                      value={values.first_name}
                    />
                    {errors.first_name && touched.first_name && (
                      <span className="errorText">{errors.first_name}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="last_name" className="label-login">Nom de famille</label>
                    <input
                      type="text"
                      placeholder="Nom de famille"
                      className="input-field-login"
                      id="last_name"
                      name="last_name"
                      onChange={handleChange("last_name")}
                      onBlur={handleBlur("last_name")}
                      value={values.last_name}
                    />
                    {errors.last_name && touched.last_name && (
                      <span className="errorText">{errors.last_name}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="label-login">Email</label>
                    <input
                      type="text"
                      placeholder="Email"
                      className="input-field-login"
                      id="email"
                      name="email"
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <span className="errorText">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="label-login">Mot de passe</label>
                    <div className="password-input-container">
                      <input
                        type={passwordHidden ? 'password' : 'text'}
                        placeholder='Mot de passe'
                        className="input-field-login"
                        id="password"
                        name="password"
                        onChange={handleChange("password")}
                        onBlur={handleBlur("password")}
                        value={values.password}
                      />
                      <div
                        className="password-toggle-icon"
                        onClick={() => setPasswordHidden(!passwordHidden)}
                      >
                        <img src={passwordHidden ? eyeSlash : eye} alt="icon" />
                      </div>
                    </div>
                    {errors.password && touched.password && (
                      <span className="errorText">{errors.password}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone" className="label-login">Téléphone</label>
                    <input
                      type="text"
                      placeholder="Téléphone"
                      className="input-field-login"
                      id="phone"
                      name="phone"
                      onChange={handleChange("phone")}
                      onBlur={handleBlur("phone")}
                      value={values.phone}
                    />
                    {errors.phone && touched.phone && (
                      <span className="errorText">{errors.phone}</span>
                    )}
                  </div>
                  {/* <div className="form-group">
                                        <label htmlFor="role" className="label-login">Rôle</label>
                                        <select
                                            id="role"
                                            name="role"
                                            onChange={handleChange("role")}
                                            onBlur={handleBlur("role")}
                                            value={values.role}
                                            className="input-field-login"
                                        >
                                            <option value="CLIENT">Client</option>
                                            <option value="COLLABORATOR">Collaborateur</option>
                                            <option value="COMPANY">Entreprise</option>
                                        </select>
                                        {errors.role && touched.role && (
                                            <span className="errorText">{errors.role}</span>
                                        )}
                                    </div> */}
                  <button type="submit" onClick={handleSubmit} className="button-login">S'inscrire</button>
                  <Link to='/login' className="login-password-link">
                    Déjà un compte ? Se connecter
                  </Link>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
